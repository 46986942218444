// To use Html5QrcodeScanner (more info below)
// import {Html5QrcodeScanner} from "html5-qrcode";
import {Html5Qrcode} from "html5-qrcode";


export default class PilotController {
	constructor(view) {
		this.view = view
		this.html5QrcodeScanner = null
		this.html5QrCode = null
		this.flipped = false
	}
	init() {
		// this.html5QrcodeScanner = new Html5QrcodeScanner("camera", { fps: 10, qrbox: {width: 250, height: 250} }, false);
		this.html5QrCode = new Html5Qrcode(/* element id */ "camera");

		Html5Qrcode.getCameras().then(devices => {
			/**
			 * devices would be an array of objects of type:
			 * { id: "id", label: "label" }
			 */
			if (devices && devices.length) {
				console.log(devices)
				this.view.devices = devices.map(device => {
					return {
						id: device.id,
						label: device.label
					}
				})
			}
		}).catch(err => {
			console.log(err)
			// handle err
		});
	}
	takePicture() {
		if (this.html5QrCode.paused) {
			return
		}
		const videoElement = document.querySelector("#camera video")
		this.html5QrCode.pause()
		const canvas = document.querySelector("#qr-canvas")

		const context = canvas.getContext("2d")

		if (!this.flipped) {
			context.translate(500, 0);
			context.scale(-1, 1);
		}

		context.drawImage(videoElement, 0, 0, 500, 375);

		const url = canvas.toDataURL("image/jpeg")

		this.view.capturedCamera = url

		this.html5QrCode.resume()
	}


	start(cameraId) {
		this.html5QrCode.start(
			cameraId,
			{
				fps: 10,    // Optional, frame per seconds for qr code scanning
			},
			(decodedText, decodedResult) => {
				// do something when code is read
				console.log(decodedText, decodedResult)
				console.log(this.view)
				this.view.detectText = decodedText
				this.view.decoderName = decodedResult.result.debugData.decoderName
				this.view.format = decodedResult.result.format.format
				this.view.formatName = decodedResult.result.format.formatName


			},
			() => {
			})
			.catch(() => {
			});
	}

	onScanSuccess(decodedText, decodedResult) {
		// handle the scanned code as you like, for example:
		console.log(`Code matched = ${decodedText}`, decodedResult);
	}

	onScanFailure(error) {
		// handle scan failure, usually better to ignore and keep scanning.
		// for example:
		console.warn(`Code scan error = ${error}`);
	}




}