<template>
    <div class="wrapper">
      <div class="camera-wrapper">
        <div id="camera" width="600px" height="600px"></div>
        <button @click="takePhoto">Capture screen</button>
      </div>
      <div class="picture-wrapper">
        <img :src="capturedCamera" />
      </div>
      <div class="devices">
        <button v-for="device in devices" v-bind:key="device.id" @click="start(device)">{{ device.label }}</button>
      </div>
    </div>
  <div class="detect-result">
    <div>{{ detectText }}</div>
    <div>{{ decoderName }}</div>
    <div>{{ format }}</div>
    <div>{{ formatName }}</div>
  </div>
</template>

<script>
import PilotController from "./PilotController";

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      isPhotoTaken: false,
      isShotPhoto: false,
      capturedCamera: null,
      devices: [],
      detectText: null,
      decoderName: null,
      format: null,
      formatName: null
    }
  },
  created() {
    this.controller = new PilotController(this)
  },
  mounted() {
    this.controller.init()
  },

  methods: {
    takePhoto() {
      this.controller.takePicture()
    },
    start(device) {
      this.controller.start(device.id)
    }
  }
}
</script>

<style scoped>
#camera {
  width: 500px;
  height: 500px;
  background-color: grey;
}
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr max-content;
    grid-template-areas:
      "camera picture"
      "button button";
    background-color: grey;
    video {
      width: 500px;
      height: 250xp;
    }
  }

  .picture-wrapper {
    padding: 10px;
    background-color: lightgray;
  }

  .picture-wrapper {
    display: flex;
    flex-direction: column;
    img {
      height: 100%;
      width: auto;
    }
  }


</style>
